import React, { useEffect } from "react";
import CliTestTab from "./CliTestTab";
import SimpleCodeTab from "./SimpleCodeTab";
import AnswerCodeTab from "./AnswerCodeTab";
import CustomCheckCodeTab from "./CustomCheckCodeTab";
import CustomTestTab from "./CustomTestTab";
import CliAnswerValueTab from "./CliAnswerValueTab";
import LegacyCodeTab from "./LegacyCodeTab";
import { Box, AppBar, Tab, Tabs } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import {
  INITIAL_CODE_TAB,
  DISPLAY_CODE_TAB,
  ANSWER_CODE_TAB,
  CUSTOM_CHECK_TAB,
  VALUE_CHECK_TAB,
  CUSTOM_TEST_TAB,
  LEGACY_CHECK_TAB,
  CLI_TEST_TAB,
  TAB_LABELS,
  SETUP_CODE_TAB,
  DEFAULT_SETUP_CODE,
  REMOTE_RUN_CODE_TAB,
  FORMAT_CODE_RUN_TAB,
  DEFAULT_FORMAT_CODE_RUN,
} from "./constants";
import { useDispatch } from "react-redux";
import { codeEditorActions } from "../../store/codeEditorSlice";
import withCodeEditor from "../../HOC/withCodeEditor";

function CodeTabs({ lesson, screen, tabs, defaultTab, codeEditor }) {
  const currentTab = codeEditor.currentTab || tabs[0];
  const dispatch = useDispatch();

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const setCurrentTab = (tab) => {
    dispatch(
      codeEditorActions.setCurrentTab({
        screenId: screen.id,
        currentTab: tab,
      })
    );
  };

  useEffect(() => {
    setCurrentTab(defaultTab);
  }, [screen.id, defaultTab]);

  const renderTabSelectors = () => {
    return tabs.map((tab) => (
      <Tab key={`${tab}-selector`} value={tab} label={TAB_LABELS[tab]} />
    ));
  };

  const renderTabComponents = () => {
    return tabs.map((tab) => renderTab(tab));
  };

  const renderTabComponent = (tab) => {
    switch (tab) {
      case INITIAL_CODE_TAB:
        return (
          <SimpleCodeTab lesson={lesson} screen={screen} field="initial_code" />
        );
      case DISPLAY_CODE_TAB:
        return (
          <SimpleCodeTab lesson={lesson} screen={screen} field="display_code" />
        );
      case SETUP_CODE_TAB:
        return (
          <SimpleCodeTab
            lesson={lesson}
            screen={screen}
            field="setup_code"
            defaultValue={DEFAULT_SETUP_CODE}
          />
        );
      case REMOTE_RUN_CODE_TAB:
        return (
          <SimpleCodeTab
            lesson={lesson}
            screen={screen}
            field="remote_run_code"
          />
        );
      case FORMAT_CODE_RUN_TAB:
        return (
          <SimpleCodeTab
            lesson={lesson}
            screen={screen}
            field="format_code_run"
            defaultValue={DEFAULT_FORMAT_CODE_RUN}
          />
        );
      case ANSWER_CODE_TAB:
        return <AnswerCodeTab lesson={lesson} screen={screen} />;
      case CUSTOM_CHECK_TAB:
        return <CustomCheckCodeTab lesson={lesson} screen={screen} />;
      case CUSTOM_TEST_TAB:
        return <CustomTestTab lesson={lesson} screen={screen} />;
      case VALUE_CHECK_TAB:
        return <CliAnswerValueTab lesson={lesson} screen={screen} />;
      case CLI_TEST_TAB:
        return (
          <CliTestTab lesson={lesson} screen={screen} currentTab={currentTab} />
        );
      case LEGACY_CHECK_TAB:
        return <LegacyCodeTab lesson={lesson} screen={screen} />;
      default:
        console.error(`Invalid tab ${tab}`);
    }
  };

  const renderTab = (tab) => {
    const isVisible = currentTab === tab;
    if (!isVisible) return null;
    return (
      <Box
        key={`${tab}-content`}
        className="editor-code dq-relative"
        sx={{ overflowX: "hidden" }}
      >
        {renderTabComponent(tab)}
      </Box>
    );
  };

  return (
    <>
      {tabs.length > 1 && (
        <AppBar position="static" color="transparent" style={{ zIndex: 1 }}>
          <Tabs
            className="editor-code-tabs"
            value={currentTab}
            variant={smScreen ? "scrollable" : "fullWidth"}
            scrollButtons="auto"
            onChange={(event, newTab) => {
              setCurrentTab(newTab);
            }}
          >
            {renderTabSelectors()}
          </Tabs>
        </AppBar>
      )}
      <Box className="editor-code">{renderTabComponents()}</Box>
    </>
  );
}

export default withCodeEditor(CodeTabs);
